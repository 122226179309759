import React, { useEffect, useRef, useState } from 'react'
import { useAuthContext } from "../providers/AuthProvider";
import { Button, Form, Input, message, Card, Spin } from "antd"
import { useApiContext } from "../providers/ApiProvider";
import { Redirect, useParams } from "react-router-dom";

export default function Profile() {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity, apiFetchEntity } = apiDispatch;

  const [form] = Form.useForm();
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const isMounted = useRef(null);
  const params = useParams();
  const isAdmin = auth.roles.includes('ROLE_ADMIN');

  useEffect(() => {
    isMounted.current = true;
    fetch()

    return () => {
      isMounted.current = false;
    }
  }, [])

  async function fetch() {
    const response = await apiFetchEntity('users', params.id);
    if (isMounted.current) {

      if (response['@type'] === "hydra:Error") {
        setError('Utilisateur introuvable')
      } else {
        setUser(response);
        setLoading(false)
      }
    }
  }

  async function handleOk() {
    const data = form.getFieldsValue();

    if (!data.password && !data.confirm && data.username === user.username && data.email === user.email)
      return;

    if (data.password && !data.confirm)
      return message.error('veuillez confirmer votre mot de passe');

    setLoading(true)
    const response = await apiUpdateEntity('users', params.id, data)
    setLoading(false)

    if (response["@type"] === "hydra:Error")
      return message.error(response["hydra:description"]);

    message.success("C'est fait !")
    setUser(response);
  }

  if ((!isAdmin && params.id !== auth.id) || error) {
    return <Redirect to={{
      pathname: "/",
      state: { status: '403', subtitle: 'vous n\'êtes pas autorisé à accéder à cette page' }
    }} />
  }

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        padding: '0 84px',
        height: '100%',
        display: "flex",
        minHeight: '100vh'
      }}
    >
      <Card className="card-style" style={{ minWidth: 550, padding: '0 2em', margin: 0 }}>
      <h2 style={{ textAlign: 'center', fontSize: '1.8em' }}>Modifier votre profil</h2>

        <Spin spinning={loading} >
          {user ?
            <Form
              layout="vertical"
              form={form}
              name="etb"
              onFinish={handleOk}
              initialValues={{
                username: user.username,
                email: user.email,
              }}
              style={{ marginTop: "2em", width: 500 }}
            >
              <Form.Item
                name="username"
                label="Nom d'utilisateur"
                rules={[
                  { required: true, message: 'Ce champs ne peut etre vide' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  { required: true, message: 'Ce champs ne peut etre vide' },
                  { type: 'email', message: 'Format email requis' }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label='Mot de passe'
                hasFeedback
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label='Confirmer mot de passe'
                dependencies={['password']}
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value)
                        return Promise.resolve();

                      return Promise.reject('Les deux mots de passe ne sont pas indentiques');
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Modifier
                </Button>
              </Form.Item>
            </Form> : null}
        </Spin>
      </Card>
    </div>
  )
}