import { Form, Input, Button } from "antd";
import { useApiContext } from "../providers/ApiProvider";

export default function AccountForm({ account }) {

    const [apiDispatch] = useApiContext()
    const {apiPostEntity} = apiDispatch

    const onFinish = async (values) => {
        console.log('Success:', values);
        const data = {
            name: values.name.trim()
        }
        const response = await apiPostEntity('accounts', data)
        console.log(response)

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form name="createAccount"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
                label="Nom"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Le nom est requis!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Valider
                </Button>
            </Form.Item>
        </Form>
    )

}