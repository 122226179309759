import { Card } from "antd";
import AccountForm from "../components/AccountForm";

export default function CreateAccount() {


    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                padding: '0 84px',
                height: '100%',
                display: "flex",
                minHeight: '100vh'
            }}
        >
            <Card className="card-style" style={{ width: 550, padding: '0 2em', margin: 0 }}>
                <div className="text-center mb-1em">
                    <img src="" alt="" style={{ width: 150 }} />
                </div>
                <h2 style={{ textAlign: 'center', fontSize: '1.8em' }}>Creer un compte</h2>
                <AccountForm />
            </Card>
        </div>
    )
}