import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from "./providers/AuthProvider";
import { ApiProvider } from "./providers/ApiProvider";

import Router from './components/Router.js';


function App() {
  return (
    <Suspense fallback="loading">
      <AuthProvider>
        <ApiProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ApiProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App;

