import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {Button, Form, Input, Modal} from "antd";

export default function TagForm({item, close, loading, setLoading}) {

    const [apiDispatch] = useApiContext()
    const {apiPostEntity, apiUpdateEntity} = apiDispatch

    const [form] = Form.useForm();
    const [visible, setVisible] = useState(true)

    async function handleFinish() {
        setLoading(true)
        const values = form.getFieldsValue()

        if (item)
            await apiUpdateEntity('tags', item.id, values)
        else
            await apiPostEntity('tags', values)

        handleClose();
        setVisible(false);
    }

    function handleClose() {
        if (typeof close === 'function')
            close();

        setVisible(false);
    }


    return (
        <Modal
            title="Edition de tag"
            visible={visible}
            onOk={handleClose}
            onCancel={handleClose}
            footer={[
                <Button onClick={handleClose}>Retour</Button>,
                <Button type="primary" onClick={handleFinish} loading={loading}>Valider</Button>
            ]}
        >

            <div>
                <Form
                    form={form}
                    onFinish={handleFinish}
                    initialValues={{
                        name: item?.name ? item.name : ''
                    }}
                >
                    <Form.Item
                        label="Nom"
                        name="name"
                        style={{width: 200}}
                        rules={[{required: true, message: 'Please input name'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    )
}