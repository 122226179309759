import React from "react";
import {Switch, Redirect} from 'react-router-dom';

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import CreateAccount from "../pages/CreateAccount";
import Admin from "../pages/Admin";
import Login from "../pages/Login";
import Logout from "../pages/Logout";


export default function Router() {

    return (
        <Switch>
            <PublicRoute exact path="/" component={Home}/>
            <PublicRoute exact path="/login" component={Login}/>

            <PrivateRoute exact path="/dashboard" component={Dashboard}/>
            <PrivateRoute exact path="/profile/:id" component={Profile}/>
            <PrivateRoute exact path="/create-account" component={CreateAccount}/>
            <PrivateRoute exact path="/admin" component={Admin}/>
            <PrivateRoute exact path="/logout" component={Logout}/>

            <Redirect to="/"/>
        </Switch>
    )
}
