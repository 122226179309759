import React from 'react';
import { Route } from 'react-router-dom';

import Layout from './Layout.js';

const PublicRoute = ({component: Component, ...rest}) => {

  const renderer = (props) => { return <Layout sibebar={false} {...props}><Component {...props} /></Layout> }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={renderer} />
  );
};

export default PublicRoute;
