import { Button } from 'antd';
import {useHistory} from 'react-router-dom';


export default function Home() {

  const history = useHistory();
   
    return (
            <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
              <h1>Welcome to our homepage </h1>
              <Button type="primary" onClick={() => history.push('/login')}>Login</Button>
            </div>
    )
}