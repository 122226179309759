import AccountList from "../components/AccountList";

export default function Dashboard() {

   
    return (
            <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
              <h1>Welcome to the dashboard</h1>
            
            <AccountList />

            </div>
    )
}