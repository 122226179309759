import React from 'react';
import { Layout } from 'antd';

import Sidebar from './Sidebar.js';

const AppLayout = ({ children, ...rest }) => {

  return (
    <Layout>
      {
        rest.sidebar && <Sidebar />
      }
      <Layout style={{ marginLeft: rest.sidebar ? 200 : 0, minHeight: '100vh' }}>
        <Layout.Content>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
