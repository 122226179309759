import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom'

import { Button, Card, Form, Input, message } from 'antd';
import { useAuthContext } from "../providers/AuthProvider";

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function Login() {

    const [authState, authDispatch] = useAuthContext();
    const { authLogin, authIsLogged } = authDispatch;
    const location = useLocation();

    const [processing, setProcessing] = useState(false);
    const isClientLogin = location.pathname === '/login';

    const onFinish = async (values) => {
        setProcessing(true)
        let response = await authLogin(values.email, values.password)
        if (response.error) {
            message.error(response.error)
            setProcessing(false)
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    if (authIsLogged())
        return authState.auth.roles.includes('ROLE_ADMIN') && !isClientLogin ? <Redirect to="/dashboard" /> :
            <Redirect to="/dashboard" />

    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                padding: '0 84px',
                height: '100%',
                display: "flex",
                minHeight: '100vh'
            }}
        >
            <Card className="card-style" style={{ width: 550, padding: '0 2em', margin: 0 }}>
                <div className="text-center mb-1em">
                    <img src="" alt="" style={{ width: 150 }} />
                </div>
                <h2 style={{ textAlign: 'center', fontSize: '1.8em' }}>Connexion</h2>
                <Form
                    {...layout}
                    name="login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        margin: '1em auto',
                        minWidth: "50%",
                        padding: "1em",
                    }}
                >
                    <Form.Item
                        label="Username"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Entrez votre email.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Mot de passe'
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Entrez un mot de passe.',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={processing}>
                            Valider
                        </Button>
                    </Form.Item>
                </Form>

            </Card>
        </div>
    );
};
