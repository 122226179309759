import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {Button, PageHeader, Table, Space} from "antd";
import TagForm from "./TagForm";

export default function TagList() {

    const [apiDispatch] = useApiContext()
    const {apiFetchCollection, apiDeleteEntity} = apiDispatch

    const [tags, setTags] = useState([])
    const [needUpdate, setNeedUpdate] = useState(0)
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(false)
    const [item, setItem] = useState(null)

    useEffect(() => {
        fetchAccounts()
    }, [needUpdate])

    async function fetchAccounts() {
        const response = await apiFetchCollection('tags', {})

        if (response['@type'] === "hydra:Collection")
            setTags(response['hydra:member'])

        setLoading(false)
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Space>
                    <Button
                        type="link"
                        onClick={() => {
                            setItem(record)
                            setEdit(true)
                        }}
                    >
                        edit
                    </Button>
                    <Button
                        danger
                        type="link"
                        onClick={() => remove(id)}
                    >
                        delete
                    </Button>
                </Space>
            )
        }
    ];

    async function remove(id) {
        setLoading(true)
        await apiDeleteEntity('tags', id)

        setNeedUpdate(needUpdate + 1)
    }

    function close() {
        setNeedUpdate(needUpdate + 1)
        setEdit(false)
        setItem(null)
    }

    if (edit)
        return <TagForm close={close} item={item} loading={loading} setLoading={setLoading}/>

    return (
        <div>

            <PageHeader
                title="Liste des tags"
                extra={<Button type="primary" onClick={() => setEdit(true)}>Creer</Button>}
            />

            <Table
                loading={loading}
                dataSource={tags}
                columns={columns}
                rowKey={(item) => item.id}
            />
        </div>
    )
}