import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useAuthContext } from "../providers/AuthProvider";
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';


const Sidebar = () => {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation()


  const isAdmin = auth.roles.includes('ROLE_ADMIN')

  return (
    <Layout.Sider style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
    }} collapsible collapsed={collapsed} onCollapse={() => { setCollapsed(!collapsed) }}>
      <div className="logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        <Menu.Item key="/dashboard" icon={<HomeOutlined />}>
        <Link to='/dashboard'>
          Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item key="/profile" icon={<UserOutlined />}>
          <Link to={`/profile/${auth.id}`}>
            Profil
          </Link>
        </Menu.Item>
        {
          isAdmin &&
          <Menu.Item key="/admin" icon={<LogoutOutlined />}>
            <Link to='/admin'>
              Administration
            </Link>
          </Menu.Item>
        }
        {
          isAdmin &&
          <Menu.Item key="/create-account" icon={<LogoutOutlined />}>
            <Link to='/create-account'>
              Creer un compte
            </Link>
          </Menu.Item>
        }
        <Menu.Item key="/logout" icon={<LogoutOutlined />}>
          <Link to='/logout'>
            Déconnexion
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
