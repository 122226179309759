import { useEffect, useState } from "react";
import { useApiContext } from "../providers/ApiProvider";
import { PageHeader, Table } from 'antd'

export default function AccountList() {

    const [apiDispatch] = useApiContext()
    const { apiFetchCollection } = apiDispatch
    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        fetchAccounts()
    }, [])

    async function fetchAccounts() {
        const response = await apiFetchCollection('accounts', {})
        console.log(response)
        if (response['@type'] === "hydra:Collection") {
            setAccounts(response['hydra:member'])
        }
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Api Key',
            dataIndex: 'apiKey',
            key: 'apiKey',
        },
    ];


    return (
        <div>
            <PageHeader
                title="Liste des comptes"
            />
            <Table dataSource={accounts} columns={columns} rowKey={(item) => item.id} />
        </div>
    )
}