import {Tabs} from 'antd';
import AccountList from '../components/AccountList';
import UserList from '../components/UserList';
import TagList from "../components/tag/TagList";

const {TabPane} = Tabs;


export default function Admin() {

    function callback(key) {
        console.log(key);
    }

    return (
        <Tabs defaultActiveKey="1" onChange={callback} type="card">
            <TabPane tab="Comptes" key="accounts">
                <AccountList/>
            </TabPane>
            <TabPane tab="Utilisateurs" key="users">
                <UserList/>
            </TabPane>
            <TabPane tab="Tags" key="tags">
                <TagList/>
            </TabPane>
        </Tabs>
    )
}